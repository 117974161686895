import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '*',
    redirect: '/home'
  },
 {
   name: 'vip',
   component: () => import('./view/vip/index'),
   meta: {
     title: 'vip',
     auth: false // 如果此路由需要微信授权请设置为true,默认为false
   }
 },
 {
   name: 'shuci',
   component: () => import('./view/vip/shuci'),
   meta: {
     title: 'vip',
     auth: false // 如果此路由需要微信授权请设置为true,默认为false
   }
 },
 {
   name: 'biBei',
   component: () => import('./view/vip/biBie'),
   meta: {
     title: 'vip',
     auth: false // 如果此路由需要微信授权请设置为true,默认为false
   }
 },
 {
   name: 'chongCi',
   component: () => import('./view/vip/chongCi'),
   meta: {
     title: 'vip',
     auth: false // 如果此路由需要微信授权请设置为true,默认为false
   }
 },
 {
   name: 'gaoPin',
   component: () => import('./view/vip/gaoPin'),
   meta: {
     title: 'vip',
     auth: false // 如果此路由需要微信授权请设置为true,默认为false
   }
 },
 {
   name: 'realTopicTrainingVIP',
   component: () => import('./view/vip/realTopicTrainingVIP'),
   meta: {
     title: 'vip',
     auth: false // 如果此路由需要微信授权请设置为true,默认为false
   }
 },
 {
   name: 'wordTrainingVIP',
   component: () => import('./view/vip/wordTrainingVIP'),
   meta: {
     title: 'vip',
     auth: false // 如果此路由需要微信授权请设置为true,默认为false
   }
 },
 {
   name: 'tongYi',
   component: () => import('./view/vip/tongYi'),
   meta: {
     title: 'vip',
     auth: false // 如果此路由需要微信授权请设置为true,默认为false
   }
 },
 {
   name: 'xeiZuo',
   component: () => import('./view/vip/xeiZuo'),
   meta: {
     title: 'vip',
     auth: false // 如果此路由需要微信授权请设置为true,默认为false
   }
 },
 {
   name: 'loginVip',
   component: () => import('./retest_h5/index'),
   meta: {
     title: 'loginVip',
     auth: false // 如果此路由需要微信授权请设置为true,默认为false
   }
 },
 {
   name: 'openingVIP',
   component: () => import('./retest_h5/vip'),
   meta: {
     title: '小白考研复试会员',
     auth: false // 如果此路由需要微信授权请设置为true,默认为false
   }
 },
 
  {
    name: 'download',
    component: () => import('./view/download/download'),
    meta: {
      title: '下载',
      auth: false // 如果此路由需要微信授权请设置为true,默认为false
    }
  },
  {
    name: 'download2',
    component: () => import('./view/download/download2'),
    meta: {
      title: '下载',
      auth: false // 如果此路由需要微信授权请设置为true,默认为false
    }
  },
  {
    name: 'home',
    component: () => import('./view/home/index'),
  },
  {
    name: 'about',
    component: () => import('./view/about/index'),
  },{
    name: 'info',
    component: () => import('./view/information/index'),
  },
  
  {
    name: 'service',
    component: () => import('./view/service/index'),
  },

	{
	  name: 'privacy',
	  component: () => import('./view/privacy/index'),
	  meta: {
	    title: '研小白英语隐私政策',
	  }
	},{
	  name: 'protocol',
	  component: () => import('./view/protocol/index'),
	  meta: {
	    title: '研小白英语服务协议',
	  }
	},
  
  {
    name: 'subject/parse/', 
    component: () => import('./view/topicDetails'),
    meta: {
      title: '题目解析详情',
      auth: false // 如果此路由需要微信授权请设置为true,默认为false
    }
  },
  {
    name: 'subject/parse/details', 
    component: () => import('./view/viewDetails'),
    meta: {
      title: '题目解析详情',
      auth: false // 如果此路由需要微信授权请设置为true,默认为false
    }
  },
  
  {
    name: 'subject/syb/', 
    component: () => import('./view/topicDetailsSyb'),
    meta: {
      title: '文章双语译文',
      auth: false // 如果此路由需要微信授权请设置为true,默认为false
    }
  },
  {
    name: 'subject/syb/details', 
    component: () => import('./view/viewDetailsSyb'),
    meta: {
      title: '题目解析详情',
      auth: false // 如果此路由需要微信授权请设置为true,默认为false
    }
  },
  //直接带有 参数的 必须要在最后 
  {
    name: 'subject/syb/:id', 
    component: () => import('./view/topicDetailsSyb'),
    meta: {
      title: '文章双语译文',
      auth: false // 如果此路由需要微信授权请设置为true,默认为false
    }
  },
  {
    name: 'subject/parse/:id', 
    component: () => import('./view/topicDetails'),
    meta: {
      title: '题目解析详情',
      auth: false // 如果此路由需要微信授权请设置为true,默认为false
    }
  },
  
  // {
  //   name: 'code/:id(\\w+)',
  //   component: () => import('./view/code'),
  //   meta: {
  //     title: '电脑信息',
  //     auth: false // 如果此路由需要微信授权请设置为true,默认为false
  //   }
  // }
  
]

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '')
})

const router = new Router({ routes })

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }
  next()
})

export { router }
