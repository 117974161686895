<template>
  <div id="app">
    <router-view /> 
  </div>
</template>

<script>


export default {
  name: "App",
  components: {
    // HelloWorld,
  },
};
</script>

<style >
	.gao{
		height: 200px;
	}
</style>
