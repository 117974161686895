var ua = navigator.userAgent.toLowerCase();
var storage = window.sessionStorage;
if(ua.match(/MicroMessenger/i)=="micromessenger") {
	 //return "weixin";
	 storage.setItem('device', false);
	 // alert("微信")
} else if (ua.match(/QQ/i) == "qq") {
	 //return "QQ";
	 storage.setItem('device', false);
	  // alert("QQ")
}else { 
	storage.setItem('device', true);
	 // alert("不是微信浏览器或手机qq浏览器");
}